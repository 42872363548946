import { createTheme } from '@mui/material/styles';

import breakpoints from './breakpoints';
import components from './overrides';
import palette from './palette';
import typography from './typography';

const baseTheme = {
  palette,
  typography,
  components,
  breakpoints,
};

const theme = createTheme(baseTheme);

export default theme;
