import { BreakpointsOptions } from '@mui/material/styles';

export default {
  values: {
    xs: 0,
    sm: 425,
    md: 768,
    lg: 1024,
    xl: 1440,
  },
} as BreakpointsOptions;
